<template>
    <div v-if="authUserPermission['mcr-material-request-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('material_request_list') }}</h3>
                        </div>
                        <div class="text-right mr-3">
                            <base-button size="sm" type="default" @click="filter" :disabled="onLoad">{{ tt('filter') }}</base-button>
                            <a :class="{'disabled' : onLoad}" :href="apiUrl+'export/material-request?type='+ table.search.type + '&mcr_number='+table.search.mcr_number + '&subject=' + table.search.subject + '&created_by='+  table.search.created_by + '&created_start='+(Array.isArray(table.search.created_at) ? table.search.created_at[0] : '')  + '&created_end=' + (Array.isArray(table.search.created_at) ? table.search.created_at[1] : '') + '&status='+  table.search.status + '&approval_status='+  table.search.approval_status + '&company='+  table.search.company +'&token='+token" class="btn btn-sm btn-default float-right" target="_blank">{{ tt('export_as_excel') }}</a>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                        <el-table-column :label="tt('type')" :prop="tt('type')" min-width="300px" sortable>
                            <template v-slot="{row}">
                                {{row.form_description }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('mcr_number')" :prop="tt('mcr_number')" min-width="159px" sortable>
                            <template v-slot="{row}">
                                {{row.mcr_number}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{row.material_number}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('subject')" :prop="tt('subject')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                {{row.subject}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('created_by')" :prop="tt('created_by')" min-width="190px" sortable>
                            <template v-slot="{row}">
                                {{row.created_by}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="191px" sortable>
                            <template v-slot="{row}">
                                {{moment(row.created_at).locale('id').format('LLL')}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('day')" :prop="tt('day')" min-width="110px" sortable>
                            <template v-slot="{row}">
                                <span v-if="row.status == 'On process'">
                                    {{countDayNow(row.created_at)}}
                                </span>
                                <span v-else>
                                    {{countDayUpdate(row.created_at, row.updated_at)}}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('status')" :prop="tt('status')" min-width="123px" sortable>
                            <template v-slot="{row}">
                                <label class="badge badge-primary" v-if="row.status == 'On process'">ON PROCESS</label>
                                <label class="badge badge-success" v-else-if="row.status == 'Approved'">APPROVED</label>
                                <label class="badge badge-danger" v-else-if="row.status == 'Rejected'">REJECTED</label>
                                <label class="badge badge-warning" v-else-if="row.status == 'Revised'">REVISED</label>
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('approval_status')" :prop="tt('approval_status')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ (row.transaction_log) ? row.transaction_log['message'] : '' }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('approval_name')" :prop="tt('approval_name')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ (row.transaction_log) ? row.transaction_log['approval_name'] : '' }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('cataloguer_name')" :prop="tt('cataloguer_name')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.cataloguer_name }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('mat_code_created')" :prop="tt('mat_code_created')" min-width="230px" sortable>
                            <template v-slot="{row}">
                                {{ ((row.sap_sync_task) && (row.sap_sync_task['mat_code_done_created'])) ? moment(row.sap_sync_task['mat_code_done_created']).locale('id').format('LLL')  : ''}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <router-link :to="'/material/mcr-form/'+row.form_type+'/'+row.mcr_code+'/'+row.mcr_item_code+'/'+row.token+'/detail'" class="dropdown-item ml-1" tag="button">{{ tt('detail') }}</router-link> 
                                        <el-dropdown-item :command="{action:'tracking', data:{mcr_code:row.mcr_code}}">{{ tt('tracking') }}
                                        </el-dropdown-item>                                     
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                    <skeleton-loading v-else/>
                </div>  
                <div class="card-footer pb-0 ">   
                    <span class="float-left">
                        <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                    </span>                             
                    <span class="float-right">
                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                    </span>
                </div>
            </div>
        </div>

        <!-- MODAL TRACKING  -->
        <modal :show.sync="formTracking.show" size="lg">
            <template slot="header">
                  <h5 class="modal-title">{{ tt('log_approval') }}</h5>
            </template>
            <tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="card shadow">
                <tab-pane>
                    <span slot="title">{{ tt('approval_tracking') }}</span>
                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.tracking" v-if="!onLoadTracking">
                        <el-table-column :label="tt('date')" :prop="tt('date')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.created_at }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('status')" :prop="tt('status')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.message }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('approver')" :prop="tt('approver')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.name }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('message')" :prop="tt('message')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.note }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <skeleton-loading v-else/>
                </tab-pane>
                <tab-pane>
                    <span slot="title">{{ tt('next_approver') }}</span>
                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.next_approver" v-if="!onLoadNextApprover">
                        <el-table-column :label="tt('name')" :prop="tt('name')">
                            <template v-slot="{row}">
                                {{ row.name }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('role')">
                            <template v-slot="{row}">
                                <span v-for="role in row.roles">{{ role.name }} <br></span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <skeleton-loading v-else/>
                </tab-pane>
            </tabs>
        </modal>

        <!-- MODAL FILTER -->
        <modal :show.sync="formFilter.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('filter') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('type') }}</label>
                <base-input :name="tt('type')">
                    <el-select class="select-danger" v-model="table.search.type" :placeholder="tt('choose_type')" clearable> 
                        <el-option class="select-danger" :value="mf['form_code']" :label="mf['form_code'] + ' - ' + mf['form_description']" :key="mf['form_code'] + ' - ' + mf['form_description']" v-for="mf in  masterForm"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('mcr_number') }}</label>
                <base-input :name="tt('mcr_number')" :placeholder="tt('mcr_number')" v-model="table.search.mcr_number"></base-input>

                <label class="form-control-label">{{ tt('subject') }}</label>
                <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="table.search.subject"></base-input>

                <label class="form-control-label">{{tt('company')}}</label>
                <base-input>
                    <el-select v-model="table.search.company" :placeholder="tt('choose_company')" clearable filterable remote :remote-method="searchCompany" :loading="formFilter.onLoad">
                        <el-option v-for="c in companies" :value="c.company_code" :label="c.company_description" :key="c.company_code"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('created_by') }}</label>
                <base-input :name="tt('created_by')" :placeholder="tt('created_by')" v-model="table.search.created_by"></base-input>

                <label class="form-control-label">{{ tt('created_at') }}</label>
                <!-- <base-input type="date" :name="tt('created_at')" :placeholder="tt('created_at')" v-model="table.search.created_at"/> -->
                <el-date-picker
                  v-model="table.search.created_at"
                  type="datetimerange"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  style="width: 450px; margin-bottom: 20px"
                  clearable>
                </el-date-picker>

                <label class="form-control-label">{{ tt('status') }}</label>
                <base-input :name="tt('status')">
                    <el-select class="select-danger" v-model="table.search.status" :placeholder="tt('choose_status')" clearable>
                        <el-option class="select-danger" value="On process" label="On process" key="On process"></el-option>
                        <el-option class="select-danger" value="Approved" label="Approved" key="Approved"></el-option>
                        <el-option class="select-danger" value="Rejected" label="Rejected" key="Rejected"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('approval_status') }}</label>
                <base-input :name="tt('approval_status')">
                    <el-select class="select-danger" v-model="table.search.approval_status" :placeholder="tt('choose_approval_status')" clearable filterable>
                        <el-option class="select-danger" v-for="data in dataTransactionLog" :value="data.message" :label="data.message"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="primary" v-on:click="filtering" :disabled="btnFilter.onLoading">
                    <span v-if="btnFilter.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                    <span v-else>
                        {{ tt('filter') }}
                    </span>
                </base-button>
            </template>
        </modal>
    </div>
    <noaccess v-else/>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import materialRequestList from '@/services/material/materialRequestList.service';
    import form from '@/services/setting/form.service';
    import draftList from "@/services/material/draftList.service";
    import transactionLog from '@/services/report/transactionLog.service';
    import companyCode from '@/services/master/companyCode.service';
    import baseApiUrl from '@/configs/config';

    var moment = require('moment');

    export default {        
        data() {
            return { 
                onLoadTracking: true,
                moment:moment,
                onLoad: true,                 
                form: {
                    title: this.tt('add_new_mcr'),
                    show: false
                },
                btnFilter: {
                    onLoading: false
                },
                formFilter: {
                    show: false,
                    onLoad:false,
                },  
                formTracking: {
                    show: false
                }, 
                table: {
                    search: {
                        type: this.$route.query.type ? this.$route.query.type : '' ,
                        mcr_number: this.$route.query.mcr_number ? this.$route.query.mcr_number : '',
                        subject: this.$route.query.subject ? this.$route.query.subject : '',
                        created_by: this.$route.query.created_by ? this.$route.query.created_by : '',
                        created_at: (this.$route.query.created_start && this.$route.query.created_end) ? [this.$route.query.created_start,this.$route.query.created_end]: '',
                        status: this.$route.query.status ? this.$route.query.status : '',
                        approval_status:this.$route.query.approval_status ? this.$route.query.approval_status : '',
                        company:this.$route.query.company ? this.$route.query.company : '',
                    },                      
                    total: 0,
                    data: [],
                    page: [],
                    tracking: [],
                    next_approver:[]
                },        
                materialRequest: {
                    
                },
                masterForm: {},
                dataTransactionLog:[],
                companies:[],
                apiUrl: baseApiUrl.apiUrl,
                token: localStorage.token,
                onLoadNextApprover:true,
                
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get();
            this.getForm();
            this.transactionLog();
        },
        methods: {
            get() {   
                let context = this; 
                if (Array.isArray(context.table.search.created_at)) {
                    var created_start = context.table.search.created_at[0];
                    var created_end   = context.table.search.created_at[1];
                } else{
                    var created_start = '';
                    var created_end   = '';
                }       
                Api(context, materialRequestList.index(this.table.page.current_page, {type: context.table.search.type, mcr_number: context.table.search.mcr_number, subject: context.table.search.subject, created_by: context.table.search.created_by, created_start: created_start, created_end: created_end, status: context.table.search.status, approval_status: context.table.search.approval_status, company: context.table.search.company })).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.page  = response.data.data.data; 
                    context.onLoad = false;      
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'tracking':
                            this.tracking(command.data.mcr_code);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.show = true;
            },
            filter() {
                this.formFilter.show = true
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_delete'));
                    } else {
                        this.alertDialog('error', this.tt('error_delete'));
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 10);
            },
            filtering() {
                let context = this;
                this.onLoad = true;
                if(this.$route.query.type){
                    this.$router.replace({'query':null})
                }
                if (Array.isArray(context.table.search.created_at)) {
                    var created_start = context.table.search.created_at[0];
                    var created_end   = context.table.search.created_at[1];
                } else{
                    var created_start = '';
                    var created_end   = '';
                } 
                Api(context, materialRequestList.index('none', {type: context.table.search.type, mcr_number: context.table.search.mcr_number, subject: context.table.search.subject, created_by: context.table.search.created_by, created_start: created_start, created_end: created_end, status: context.table.search.status, approval_status: context.table.search.approval_status, company: context.table.search.company })).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.page  = response.data.data.data; 
                    context.onLoad = false; 
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                    context.btnFilter.onLoading = false;
                    context.formFilter.show = false;
                    context.$router.replace({query:{type: context.table.search.type, mcr_number: context.table.search.mcr_number, subject: context.table.search.subject, created_by: context.table.search.created_by, created_start: created_start, created_end: created_end, status: context.table.search.status, approval_status: context.table.search.approval_status, company: context.table.search.company }})
                }).call()
            },
            getForm() {
                let context = this;               
                Api(context, form.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.masterForm = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.masterForm = [];
                    }
                })
                .call()
            },
            tracking(mcr_code) {
                this.formTracking.show = true;

                let context = this;
                Api(context, draftList.log_approval(mcr_code))
                .onSuccess(function(response) {
                    context.table.tracking = response.data.data;
                })
                .onError(function(error) {
                    if (error.response.status == 404) {
                      context.table.tracking = [];
                    }
                })
                .onFinish(function() {
                    context.onLoadTracking = false;
                })
                .call();

                Api(context, draftList.next_approver(mcr_code))
                .onSuccess(function(response) {
                    context.table.next_approver = response.data.data;
                })
                .onError(function(error) {
                    if (error.response.status == 404) {
                      context.table.next_approver = [];
                    }
                })
                .onFinish(function() {
                    context.onLoadNextApprover = false;
                })
                .call();
            },
            countDayUpdate(create, update){
                if (create) {
                    var a = moment(create);
                    var b = moment(update);
                    return b.diff(a, 'days') + ' days'
                }
            },
            countDayNow(create){
                if (create) {
                    var a = moment(create);
                    var b = new Date();
                    var c = moment(b)
                    return c.diff(a, 'days') + ' days'
                }
            },
            transactionLog() {
                let context = this;
                Api(context, transactionLog.index({date:'', search:'approval_action'})).onSuccess(function(response) {
                    const filterData = [...new Map(response.data.data.data.map(item =>[item['message'], item])).values()];
                    context.dataTransactionLog = filterData
                }).onError(function(error) {         
                    context.dataTransactionLog = []
                }).call();
            },
            searchCompany(query){
                let context = this;
                this.formFilter.onLoad = true;
                Api(context, companyCode.get(null, {per_page:'none', search:query})).onSuccess((response) =>{
                    context.companies = response.data.data.data.data;
                    context.formFilter.onLoad = false;
                }).onError((e) =>{
                    context.companies = [];
                }).call()
            },
        }   
    };
</script>
<style></style>